import React from "react"
import GiftCards from "../../../../components/body/pages/en-ng/legal/disclaimer/giftCards"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

// src/components/body/pages/en-ng/legal/disclaimer/giftCards.js
const DisclaimerGiftCards = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/disclaimer/giftCards/"}
      title="Disclaimer GiftCards | Kuda | The Money App for Africans"
    />
    <GiftCards />
  </Layout>
)

export default DisclaimerGiftCards
